<template>
  <div>
    <InnerDetail>
      <template v-slot:title>
        {{ $route.meta.title }}
      </template>
      <template v-slot:detail>
        <div class="detail-content">
          <p class="sub-title">项目概述：</p>
          <span>
            教务系统实现了添加潜在学员，学员报名 、试听记录、跟进记录、教务中心、教师列表、物品/费用、学员课程、缺勤统计信息、学生人脸考勤记录、考勤统计 、订单列表、课消记录 、老师工资、提成记录统计。课后成长档案和消息通知等功能模块。
          </span>
          <span>
            用户端采用公众号H5形式分为老师端、家长端。主要包括 学员签到 档案和课程管理 订单管理、成长档案模块，拍照上传学员图片进行人脸识别签到会实时发送公众号通知消息给家长手机，实时获取孩子到课情况。
          </span>
          <span>
            帮助机构实现招生引流、智能化教务排课、信息化学员管理，家长学校沟通，致力于教培行业的管理，提升运营能力。
          </span>
        </div>
        <div class="detail-img">
          <div v-for="i in 2" :key="i" class="admin-img">
            <img :src="require(`@/assets/img/product/jiaowu${i}.jpg`)" alt="">
          </div>
          <div v-for="i in 6" :key="i">
            <img :src="require(`@/assets/img/product/jiaowu_mobile${i}.jpg`)" alt="">
          </div>
        </div>
      </template>
    </InnerDetail>
  </div>
</template>
<script>
import InnerDetail from "@/views/product/components/InnerDetail.vue";

export default {
  name: 'teachingSys',
  components: {InnerDetail},
}
</script>
